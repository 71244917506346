@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";
@font-face {
  font-family: 'Starjedi';
  src: url('./assets/fonts/Starjedi.ttf') format('truetype');
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(181, 181, 181); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #31748a; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4ab2d5; 
}

.carousel-container {
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  mask-image: linear-gradient(to right, transparent 0%, white 25%, white 75%, transparent 100%);
  margin: 10px;
  position: relative;
}

.carousel {
  display: flex;
  width: max-content; /* Allow the carousel to expand naturally */
  animation: scroll-left 20s linear infinite;
}

.carousel img {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-right: 20px;
  border-radius: 10px;
}

.carousel:hover {
  animation-play-state: paused;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}


a {
  color: #4ab2d5;
  text-decoration: none;
}

h3 {
  color: #4ab2d5;
}

.App {
  height: 100vh;
  width: 100vw;
  font-family: "Rubik", sans-serif;
  overflow-x: hidden;
  background: linear-gradient(0deg, rgb(49 52 69), transparent);
}

.navigation {
  display: flex;
  flex-direction: row;
  line-height: 80px;
  margin: auto;
  margin-bottom: 30px;
  background: linear-gradient(180deg, #31344570, #00000000);
}

.navContain {
  max-width: 1500px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navLinkIcon {
  display: none;
}

.navigation a {
  color: #fff;
  font-size: 20px;
  padding: 0px 30px;
  position: relative;
  text-decoration: none;
  text-shadow: #161616 2px 0 4px;
  transition: all .2s ease-in-out;
  padding-top: 5px;
}

.navigation a:hover {
  background: linear-gradient(180deg, #ffffff1a, #00000000);
  transition: all .2s ease-in-out;
  backdrop-filter: blur(6px);
  
}

.navigation .links {
  max-width: 1600px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.socials {
  display: none;
}

.background {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  filter: blur(5px) brightness(0.5);
  transform: scale(1.1);
  background-color: #1e1f25;
}

.mainBody {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  justify-content: space-evenly;
  align-items: center;
}

.mainBody #wrap {
  max-width: 1500px;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: flex-start;
}

.join {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  margin-right: 10px;

}

.JoinButton {
  font-size: 31px;
  background: #0a81a9;
  width: -moz-fit-content;
  width: fit-content;
  padding: 15px 25px;
  transition: .2s ease-in-out background, .2s ease-in-out transform;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 14%);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  border: var(--box-border);
  border-radius: 30px;
  color: white;
  margin-top: 5%;
  font-weight: 500;
}

.JoinButton:hover {
  cursor: pointer;
  background: #4ab2d5;
  transition: .2s ease-in-out background, .2s ease-in-out transform;
  transform: scale(1.05);
}


.JoinButton svg {
  filter: drop-shadow(2px 1px 10px  rgb(22,22,22));
}

.birb {
  height: 70px;
  margin-right: 25px;
}

.break {
  display: block;
  height: 2px;
  width: 100%;
  position: relative;
  margin: 8% 0;
  display: flex;
  justify-content: center;
}
.break:after {
  content: "";
    background: -webkit-linear-gradient(left, rgb(255 255 255 / 0%) 0%, rgba(117, 122, 148, 0.442) 50%, rgb(255 255 255 / 0%) 100%);
  display: block;
  height: 2px;
  width: 70%;
  position: absolute;
  bottom: 0;
}

.down-arrow {
  position: absolute;
  top: calc(90vh - 80px);
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  animation: jumpInfinite 1.5s infinite;
  color: white;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -10px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

.headerImages {
  max-width: 45%;
  overflow: hidden;
  background: rgb(22,22,22);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  width: 587px;
  min-width: 587px;
  height: 314px;
  border-radius: 3px;
  border: var(--box-border);
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 14%);
}

.headerImages iframe {
    display: block;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 14%);
    width: 100%;
    height: 100%;
}

#title {
  font-size: 100px;
  color: white;
  font-family: 'Starjedi', cursive;
  text-shadow: rgb(22 22 22) 0px 0px 5px;
  width: 100%;
  text-align: center;
  margin-bottom: 10%;
}

#subtitle {
  font-size: 30px;
  font-style: italic;
  margin-bottom: 10px;
  color: #4ab2d5;
  font-weight: 700;
  min-height: 90px;
}

#desc {
  font-size: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

#man_rpg {
  height: 600px;
}

.stats {
  color: white;
  font-size: 50px;
  text-shadow: rgb(22 22 22) 0px 0px 5px;
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  margin: auto;
  width: 100%;
  margin-bottom: 10px;
 
}

#stat {
  display: flex;
  flex-direction: column;
  width: 300px;
  align-items: center;
  padding: 10px 20px;
  padding-bottom: 15px;
}

.map, .performance, .economy, .performance {
  color: white;
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-shadow: rgb(22 22 22) 0px 0px 5px;
}

.performance {
  flex-direction: row-reverse;
  height: 380px;
}

.performance p {
  align-items: flex-end;
}

.performance img {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 14%);
  border-radius: 3px;
  height: inherit;
  border: var(--box-border);
}

.image-gallery {
  margin-right: 10px;
  padding-right: 0;
  border: var(--box-border);
  border-radius: 3px;
}

.image-gallery-slides {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 14%);
  border-radius: 3px;
}

.economy .image-gallery-slides {
  max-height: 342px;
  max-width: 1700px;
}

svg {
  color: white;
}

#statSub {
  font-size: 21px !important;
}

#stat svg {
  height: 40px;
}

.copyright {
  color: #ffffff66;
}

#blocktitle {
  font-size: 30px;
  font-weight: 700;
  color: #4ab2d5;
}

#block {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 19px;
  
}

.countWrap {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 200px;
}

.imgwrap {
  margin-left: 5px;
  text-align: center;
  height: inherit;
}

@media (max-width: 1300px) {
  .navContain{
    flex-direction: column;
  }

  #title {
    text-align: center;
    font-size: 64px;
  }

  .navigation {
    margin-bottom: 0;
    line-height: 20px;
    width: 100%;
    bottom: 0;
    position: absolute;
    z-index: 10 !important;
    background: #313445;
    padding-top: 10px;
  }

  .svg-inline--fa {
    height: 1.5em;
  }

  .navigation .links {
    justify-content: space-evenly;
  }

  .navigation a {
    padding: 10px;
    top: 0;
    font-size: .8rem;
  }

  .stats {
    margin: auto;
    font-size: 18px;
    width: 90%;
    margin-bottom: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;
  }

  #statSub {
    font-size: 11px !important;
    text-wrap: nowrap;
  }

  .countWrap {
    width: unset !important;
    margin-left: 5px;
  }

  .countWrap span {
    width: 70px;
  }

  .mainBody #wrap {
    height: unset;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100vw;
  }

  #block {
    width: 85%;
    margin: auto;
    text-align: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 0;
  }

  .join {
    align-items: center;
    text-align: center;
    margin-right: 0;
  }

  .JoinButton {
    display: none;
  }

  #subtitle {
    font-size: 17px;
    min-height: 55px;
    margin-bottom: 10px;
  }

  #desc {
    font-size: 17px;
    margin-bottom: 0;
  }

  .Typewriter {
    min-height: unset !important;
  }

  .headerImages {
    max-width: 95%;
    width: 100%;
    min-width: unset;
    margin: auto;
    margin-top: 10px;
    height: 200px;
  }

  .headerImages iframe {
    width: 100%;
  }

  .break:after {
    width: 100%;
  }

  .performance {
    height: unset;
  }

  .map, .performance, .economy {
    flex-direction: column;
  }

  .image-gallery {
      max-width: 95%;
      margin-right: 0;
      margin: auto;
      margin-top: 10px;
  }

  #blocktitle {
    font-size: 23px;
  }

  .imgwrap {
    width: 95%;
    margin: auto;
    margin-top: 15px;
  }

  .performance img {
    height: auto;
    width: 100%;
  }

  .socials {
    width: unset;
    justify-content: space-evenly;
  }

  .socials a {
    margin-right: unset;
    margin-left: 15px;
  }

  .logoContainer {
    margin: 10% !important;
    margin-top: 10px !important;
  }

  .birb {
    height: 45px;
  }

  #stat {
    width: unset;
    padding: 10px 10px;
  }

  .carousel-left img, .carousel-right img {
    max-height: 120px !important;
  }

  .map p, .economy p, .performance p {
    font-size: unset;
  }

  .App {
    height: 91.8vh;
  }

  .down-arrow {
    display: none !important;
  }

  .navLinkIcon {
    display: flex;
    margin: auto;
  }

  .navigation a:hover {
    background: none;
    transition: all .2s ease-in-out;
    backdrop-filter: none;
    
  }

  #stat svg {
    height: 22px;
  }

  #countup {
    line-height: 32px;
    margin-top: 10px;
  }
  
}













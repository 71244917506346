.carousel-container {
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
    mask-image: linear-gradient(to right, transparent 0%, white 25%, white 75%, transparent 100%);
    margin: 10px;
    position: relative;
}

.carousel-left {
    display: flex;
    animation: scroll-left 60s linear forwards;
}

.carousel-right {
    display: flex;
    animation: scroll-right 60s linear forwards;
}

.carousel-left img, .carousel-right img {
    width: 100%;
    max-height: 200px;
    margin-right: 30px;
    border-radius: 6px;
}

.carousel-left:hover, .carousel-right:hover {
    animation-play-state: paused;
}

@keyframes scroll-left {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes scroll-right {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}
